import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import AgoraUIKit from "agora-react-uikit";
import AgoraRTC from "agora-rtc-sdk-ng";
import cameraIcon from "../Assets/Images/camera_icon.svg";
import videoCallIcon from "../Assets/Images/video_call.svg";
import micIcon from "../Assets/Images/mic_icon.svg";
import micOffIcon from "../Assets/Images/mic_off_icon.svg";
import otherUser from "../Assets/Images/other_user.svg";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import endCallIcon from "../Assets/Images/end_call.svg";

export default function Sharecall(props) {
  const params = useParams();
  const client = useRef(null);
  const appId = "5da64dc39db9467485eeaac6d515f210";
  const localAudioTrack = useRef(null);
  const localVideoTrack = useRef(null);
  const localPlayerRef = useRef(null);
  const [name, setName] = useState("");
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [isCameraOff, setIsCameraOff] = useState(false);

  const [err, setErr] = useState("");
  const [openvideo, setOpenvideo] = useState(false);
  const [url, setUrl] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [rtcPropsId, setRtcPropsId] = useState(null); // Agora RTC properties
  const [videoCall, setVideoCall] = useState(false); // To handle video call start/stop
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const [isJoined, setIsJoined] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const handleExistingUser = async (user) => {
    await client.current
      .subscribe(user, "video")
      .catch((e) => console.error(e));
    await client.current
      .subscribe(user, "audio")
      .catch((e) => console.error(e));

    setRemoteUsers((prevUsers) => {
      const existingUser = prevUsers.find((u) => u.uid === user.uid);
      if (!existingUser) {
        return [...prevUsers, { ...user, isCameraOff: !user.hasVideo }];
      }
      return prevUsers;
    });

    if (user.hasVideo) {
      user.videoTrack.play(`remote-player-${user.uid}`);
    }

    if (user.hasAudio) {
      user.audioTrack.play();
    }
  };

  const save = (e) => {
    e.preventDefault();

    console.log(props.data);
    if (name.trim() === "") {
      setErr("Please enter the name");
      //alert("required both field");
    } else {
      setOpenvideo(true);
    //   const header = {
    //     secretKey: "CcJYfbsgItHpTQPFr5lg",
    //   };
    //   const data = {
    //     clientCode: "CCM@@202!",
    //     userType: "PARTICIPANT",
    //     meetingKey: params.id,
    //     memberName: location?.state?.name ? location?.state?.name : name,
    //     memberEmail: "test@gmail.com",
    //     memberMobile: "1234567890",
    //   };

    //   axios
    //     .post(
    //       "https://cb.maggieplus.com/admin-api/client/meeting/authentication",
    //       data,
    //       { headers: header }
    //     )
    //     .then((result) => {
    //       //setUrl(result.data.response.url)
    //       //setOpenvideo(true)
    //       if (params.type == "A") {
    //         window.location.href = result.data.response.url + "/1";
    //         setName("");
    //       } else {
    //         window.location.href = result.data.response.url;
    //         setName("");
    //       }

    //       //history.replace({state:{name:name,videoUrl:result.data.response.url}})
    //     })
    //     .catch((err) => {
    //       console.log("Err", err);
    //     });
      setupAgora()
    }
  };

  useEffect(()=>{
    setupAgora();
  },[])


  const handleUserPublished = async (user, mediaType) => {
    await client.current.subscribe(user, mediaType);

    setRemoteUsers((prevUsers) => {
      const existingUser = prevUsers.find((u) => u.uid === user.uid);
      if (!existingUser) {
        return [...prevUsers, { ...user, isCameraOff: mediaType !== "video" }];
      }
      return prevUsers.map((u) =>
        u.uid === user.uid ? { ...u, isCameraOff: mediaType !== "video" } : u
      );
    });

    if (mediaType === "video") {
      user.videoTrack.play(`remote-player-${user.uid}`);
    }

    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  };

  const setupAgora = async () => {
    try {
      // Initialize Agora client
      client.current = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      const channelId = params?.id ? String(params.id) : "defaultChannel";
      const sanitizedChannelId = channelId.replace(/[^a-zA-Z0-9_-]/g, "");

      // Fetch token and UID from server
      const response = await axios.get(
        `https://common-stage.curebay.in/cs/token/generate?channelName=${sanitizedChannelId}&role=subscriber`
      );
      const token = response.data?.data?.token;
      const uuid = response.data?.data?.uid;

      if (!token || !appId || !sanitizedChannelId) {
        throw new Error("App ID, Token, or Channel ID is undefined");
      }

      // Join the channel
      await client.current.join(appId, sanitizedChannelId, token, uuid);
      setVideoCall(true);

      // Create local audio and video tracks
      localAudioTrack.current = await AgoraRTC.createMicrophoneAudioTrack();
      localVideoTrack.current = await AgoraRTC.createCameraVideoTrack();

      // Ensure the localPlayerRef is rendered and available
      if (!localPlayerRef.current) {
        console.error(
          "Local video player element is not found at setup. Retrying in 100ms..."
        );
        setTimeout(() => {
          if (localPlayerRef.current) {
            localVideoTrack.current.play(localPlayerRef.current);
            console.log("Local video element found and video played.");
          } else {
            console.error("Local video player element is still not found.");
          }
        }, 100);
      } else {
        localVideoTrack.current.play(localPlayerRef.current);
      }

      // Publish audio and video tracks
      await client.current.publish([
        localAudioTrack.current,
        localVideoTrack.current,
      ]);
      console.log("Published local video and audio tracks to the channel");

      setIsJoined(true);

      // Handle existing remote users
      client.current.remoteUsers.forEach((user) => {
        handleExistingUser(user);
      });

      // Event listeners for user publishing/unpublishing
      client.current.on("user-published", handleUserPublished);
      client.current.on("user-unpublished", handleUserUnpublished);
      client.current.on("user-left", handleUserLeft);
    } catch (error) {
      console.error("Error setting up Agora:", error.message, error);
    }
  };

  const handleUserUnpublished = (user, mediaType) => {
    if (mediaType === "video") {
      setRemoteUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.uid === user.uid ? { ...u, isCameraOff: true } : u
        )
      );
    }
  };

  useEffect(() => {
    //alert(JSON.stringify(location?.state))
    if (location?.state?.name) {
      setOpenvideo(true);
      setUrl(location?.state?.videoUrl);
    }
  }, []);

  const onChangeHandler = (fieldName, value) => {
    if (fieldName === "name") {
      setName(value);
      setErr("");
    }
  };
  const handleUserLeft = (user) => {
    console.log("User left:", user);
    const updatedRemoteUsers = remoteUsers.filter(
      (remoteUser) => remoteUser.uid !== user.uid
    );
    setRemoteUsers(updatedRemoteUsers);
    const remotePlayerElement = document.getElementById(
      `remote-player-${user.uid}`
    );
    if (remotePlayerElement) {
      remotePlayerElement.parentNode.removeChild(remotePlayerElement);
    }
  };
  const getPatientInitials = (name) => {
    const names = name?.split(" ") || [];
    const initials = names.map((n) => n[0]?.toUpperCase()).join("");
    return initials || "P"; // Fallback to "P" if no initials are found
  };
  const callbacks = {
    EndCall: () => setVideoCall(false),
  };

  const getGridClass = () => {
    const totalUsers = remoteUsers.length + 1; // Local user + remote users
    if (totalUsers === 1) return "grid-cols-1";
    if (totalUsers === 2) return "grid-cols-2";
    if (totalUsers <= 4) return "grid-cols-2 grid-rows-2";
    return "grid-cols-3 grid-rows-2";
  };

  const toggleMute = async () => {
    if (localAudioTrack.current) {
      await localAudioTrack.current.setEnabled(!isMuted);
      setIsMuted(!isMuted);
    }
  };
  const playLocalVideo = () => {
    if (localPlayerRef.current) {
      // Clear any previous video feed
      localPlayerRef.current.innerHTML = "";
      if (localVideoTrack.current) {
        localVideoTrack.current.play(localPlayerRef.current);
      }
    }
  };
  const endconsultation = async () => {
    if (localAudioTrack.current) localAudioTrack.current.close();
    if (localVideoTrack.current) {
      localVideoTrack.current.stop();
      localVideoTrack.current.close();
    }
    await client.current.leave();
    setIsJoined(false);
    setVideoCall(false);
    history.push(APP_ROUTES.DASHBOARD);
  };
  const toggleCamera = async () => {
    try {
      if (isCameraOff) {
        if (!localVideoTrack.current) {
          localVideoTrack.current = await AgoraRTC.createCameraVideoTrack();
        }

        // Delay playing local video to ensure everything is set up
        setTimeout(() => {
          playLocalVideo();
        }, 200); // Minor delay to allow DOM updates

        await client.current.publish([localVideoTrack.current]);
        console.log("Republished local video track");
      } else {
        if (localVideoTrack.current) {
          await client.current.unpublish([localVideoTrack.current]);
          localVideoTrack.current.stop();
          localVideoTrack.current.close();
          localVideoTrack.current = null;
          console.log("Unpublished and closed local video track");
        }
      }
      setIsCameraOff(!isCameraOff);
    } catch (error) {
      console.error("Error toggling camera:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col h-screen bg-gray-100">
                        <div className={`grid ${getGridClass()} gap-2 flex-1`}>
                          {/* Local Video */}
                          <div className="relative bg-black">
                            {isCameraOff ? (
                              <div className="flex items-center justify-center w-full h-full bg-gray-800">
                                <img src={otherUser} alt="User Placeholder" className="w-20 h-20 opacity-50" />
                              </div>
                            ) : (
                              <div
                                ref={localPlayerRef}
                                id="local-player"
                                className="w-full h-full"
                              ></div>
                            )}
                          </div>

                          {/* Remote Videos */}
                          {remoteUsers.map((user) => (
                            <div
                              key={user.uid}
                              className="relative bg-black w-full h-full"
                            >
                              {user.isCameraOff ? (
                                <div className="flex items-center justify-center w-full h-full bg-gray-800">
                                  {/* Placeholder for remote camera off */}
                                </div>
                              ) : (
                                <div
                                  id={`remote-player-${user.uid}`}
                                  className="w-full h-full"
                                ></div>
                              )}
                            </div>
                          ))}
                        </div>

                        {/* Controls */}
                        <div className="flex justify-center bg-black bg-opacity-75 p-4">
                          <button
                            onClick={toggleMute}
                            className="text-white bg-white rounded-full p-3"
                          >
                            <img
                              src={isMuted ? micIcon : micOffIcon}
                              alt="Mic Icon"
                              className="w-8 h-8"
                            />
                          </button>
                          <button
                            onClick={toggleCamera}
                            className="text-white bg-white rounded-full p-3 mx-4"
                          >
                            <img
                              src={isCameraOff ? cameraIcon : videoCallIcon}
                              alt="Camera Icon"
                              className="w-8 h-8"
                            />
                          </button>
                          <button
                            onClick={endconsultation}
                            className="text-white bg-red-500 rounded-full p-3"
                          >
                            <img
                              src={endCallIcon}
                              alt="End Call Icon"
                              className="w-8 h-8"
                            />
                          </button>
                        </div>
                      </div>
    </>
  );
}
